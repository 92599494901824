@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GreedBold";
  src: url("/fonts/greed-bold.woff2");
  font-display: block;
}

@font-face {
  font-family: "SctoRegular";
  src: url("/fonts/scto-grotesk-a-regular.woff");
  font-display: block;
}

@font-face {
  font-family: "SctoBold";
  src: url("/fonts/scto-grotesk-a-bold.woff");
  font-display: block;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

html,
body {
  scroll-padding-top: 94px;
  scroll-behavior: smooth;
}

@media screen and (min-width: 1280px) {

  html,
  body {
    scroll-padding-top: 130px;
  }
}

@media (prefers-reduced-motion) {

  html,
  body {
    scroll-behavior: auto;
  }
}

img[loaded="true"] {
  /* NEEDED TODO: investigate why opacity-0 isn't removed on dynamic cover image*/
  opacity: 1;
}

@layer components {
  *:focus {
    @apply ring-0 outline-none;
  }

  *:focus-visible {
    @apply ring-offset-2 ring-4 ring-brand rounded outline-brand;
  }

  p:not(:last-of-type) {
    @apply mb-4;
  }

  ::selection {
    @apply bg-brand text-black;
  }
}

.respect-index {
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes wiggle {

  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.animate--wiggle {
  animation-name: wiggle;
  animation-delay: 2ms;
  animation-iteration-count: 2;
  animation-duration: 2000ms;
  animation-timing-function: linear;
}

.animate--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1000ms;
}

.animate--fade-out {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 1000ms;
}

/* Elevio */
[data-page-id="/forms/[[...slug]]"] #_elev_io {
  display: none;
}

/* Note to remove text-new-* variables when we have fully migrated to the new typography components */ 
:root {
  --color-transparent: theme(colors.transparent);
  --color-current: theme(colors.current);
  --color-white: theme(colors.white);
  --color-black: theme(colors.black);
  --color-brand: theme(colors.brand);
  --color-light-grey: theme(colors.light-grey);
  --color-mid-grey: theme(colors.mid-grey);
  --color-dark-grey: theme(colors.dark-grey);
  --color-charcoal: theme(colors.charcoal);
  --color-orange: theme(colors.orange);
  --color-green: theme(colors.green);
  --color-green-dark: theme(colors.green-dark);
  --color-blue: theme(colors.blue);
  --color-pink: theme(colors.pink);
  --color-red: theme(colors.red);
  --text-new-xs: 0.75rem;  /* 12px */
  --text-new-sm: 0.875rem; /* 14px */
  --text-new-base: 1rem;   /* 16px */
  --text-new-md: 1.125rem; /* 18px */
  --text-new-lg: 1.25rem;  /* 20px */
  --text-new-xl: 1.5rem;   /* 24px */
  --text-new-2xl: 2rem;    /* 32px */
  --text-new-3xl: 2.5rem;  /* 40px */
  --text-new-4xl: 3rem;    /* 48px */
  --text-new-5xl: 5rem;    /* 80px */
  --text-new-6xl: 7.5rem;  /* 120px */
}

/* Theme colors */
:root,
[data-theme="default"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="dark"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-light-grey);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="dark-alt"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-white);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="light"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-mid-grey);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="brand"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-black);
  --color-text-tertiary: var(--color-white);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="brand-alt"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-brand);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);

}

[data-theme="color"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="header"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="footer"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-light-grey);
  --color-text-secondary: var(--color-brand);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="blue"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="light-blue"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="pink"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="orange"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="green"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-black);
  /* TODO: This needs some thought*/
}

[data-theme="light-grey"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-dark-grey);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="form-input"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="product-card"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="product-card"]:hover {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-white);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-theme="inherit"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-black);
  --color-text-secondary: var(--color-white);
  --color-text-tertiary: var(--color-brand);
  --color-text-disabled: var(--color-light-grey);
  --color-text-icon-disabled: var(--color-black);
  --color-text-error: var(--color-orange);
  --color-text-success: var(--color-green-dark);
}

[data-richtext-color="green"] {
  --color-text-inherit: inherit;
  --color-text-default: var(--color-green);
  --color-text-secondary: var(--color-green);
  --color-text-tertiary: var(--color-green);
  --color-text-disabled: var(--color-light-green);
  --color-text-icon-disabled: var(--color-green);
  --color-text-error: var(--color-green);
  --color-text-success: var(--color-green-dark);
}

del {
  text-decoration: line-through;
}

.backface-hidden {
  backface-visibility: hidden;
}